<template>
  <div id="ongoing" class="tab-pane active tab-box">
    <div class="orders-lst skelton" v-if="is_skel_loader && ongoingorders.length == 0">
      <a href="javascript:void(0);">
        <div class="order-item d-flex align-items-start mb-2 pb-2">
          <div class="img-cls resimg-squre | d-flex align-items-center justify-content-center">
            <skeleton-loader-vue width="85px" height="85px" style="border-radius: 5px;" />
          </div>
          <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
            <div class="orders-status-main delivery-link">
              <h6 class="mb-1"><skeleton-loader-vue type="string" width="130px" height="20px" /></h6>
              <skeleton-loader-vue type="string" width="50px" height="20px" />
              <p class="mt-2"><skeleton-loader-vue type="string" width="100px" height="20px" /></p>
            </div>
            <div class="order-status">
              <p class="order-status-btn"><skeleton-loader-vue type="circle" width="100px" height="20px"
                  style="border-radius: 20px !important;" /></p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="orders-lst">
      <router-link v-if="ongoingorders.length > 0 && !is_skel_loader" v-for="(order, index) in ongoingorders" :key="index"
        :to="{ name: 'order-status', params: { order_id: base64_encode(order.id) } }">
        <div class="order-item d-flex align-items-start mb-2 pb-2">
          <div class="img-cls resimg-squre">
            <img :src="order.icon_image" />
          </div>
          <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100  ms-3">
            <div class="orders-status-main delivery-link on-going-order">
              <h6 class="mb-1">{{ order.restaurant_name }}</h6>
              <span v-html="$auth.setting.currency" class="currency"></span><span>{{ parseFloat(order.total_amount ||
                0).toFixed(2) }}</span>
              <p class="mt-2">{{ moment(order.order_date).fromNow() }}</p>
            </div>
            <div class="order-status" v-if="order.order_status == 'Placed'">
              <p class="readytoserve order-status-btn">{{ $t('Placed') }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'ReadyToCollected'">
              <!-- <div class="order-status" v-else-if="order.order_status == 'InKitchen'"> -->
              <p class="readytoserve order-status-btn">{{ $t('Processing') }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'ReadyToServe'">
              <p class="readytoserve order-status-btn">{{ $t('Processing') }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'OnTheWay'">
              <p class="readytoserve order-status-btn">{{ $t('On The Way') }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Arrived'">
              <p class="readytoserve order-status-btn">{{ $t('Arrived') }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Delivered'">
              <p class="delivered order-status-btn">{{ order.order_status }}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Cancelled'">
              <p class="canceled order-status-btn">{{ order.order_status }}</p>
            </div>
          </div>
        </div>
      </router-link>
      <div v-if="ongoingorders.length == 0 && !is_skel_loader"
        class="order-offers | position-absolute top-50 start-50 translate-middle">
        <p class="text-center">{{ $t('No Ongoing Order Found') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  props: ["selectTab"],
  components: { 'skeleton-loader-vue': VueSkeletonLoader },
  computed: {
    ...mapGetters("order", ["onGoingOrders"]),
  },
  data() {
    return {
      ongoingorders: [],
      moment: moment,
      is_skel_loader: true,
    };
  },
  mounted() {
    if (this.selectTab == '0') {
      this.OnGoingOrders();
    }
  },
  methods: {
    ...mapActions("order", ["getOnGoingOrder"]),
    OnGoingOrders() {
      this.getOnGoingOrder({
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
        user_id: "",
        totalrecord: 100,
        skip: 0,
      }).then((data) => {
        if (data.code == 200) {
          this.is_skel_loader = false;
          this.ongoingorders = data.Result;
        } else {
          this.is_skel_loader = false;
        }
      });
    }
  }
};
</script>